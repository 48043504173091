import React from "react";
import './Curriculum.css'

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";


//images
import stdkg from "./imgs/Student_in_kg_1.jpg"
import prim from "./imgs/primary.jpg"
import girls from "./imgs/girls.JPG"
import boys from "./imgs/Boyes.JPG"


const Curriculum = ()=>{

    return(
        <>
            <Banner1/>
            <Header1/>
            <div className="school-descriptions-containe">
                <div className="school-descriptio">
                    <h2 className="text-primary-emphasis text-center">Pre-Primary School</h2>
                    <img className="kg1" src={stdkg} alt="Pre-Primary School" />
                    <div className="descriptio-text">
                    <p>
                        Pre-primary education typically refers to the education of children
                        before they enter formal schooling. It focuses on early childhood
                        development and often includes activities to enhance social,
                        cognitive, and physical skills.
                    </p>
                    </div>                    
                </div>
                <div className="school-descriptio">
                    <h2 className="text-primary-emphasis text-center">Primary School</h2>
                    <img src={prim} alt="Primary School" />
                    <div className="descriptio-text">
                    <p>
                        Primary school is the first stage of compulsory education and is
                        usually for children between the ages of 5 and 11. It lays the
                        foundation for further learning and typically covers basic subjects
                        such as mathematics, language arts, science, and social studies.
                    </p>
                    </div>
                    
                </div>
                <div className="school-descriptio">
                    <h2 className="text-primary-emphasis text-center">Middle School</h2>
                    <img src={girls} alt="Middle School" />
                    <div className="descriptio-text">
                    <p>
                        Middle school, also known as junior high school, is an educational
                        stage that typically covers the transition period between primary
                        school and high school. Students in middle school are usually
                        between the ages of 11 and 14 and often experience more specialized
                        instruction in various subjects.
                    </p>
                    </div>
                    
                </div>
                <div className="school-descriptio">
                    <h2 className="text-primary-emphasis text-center">Senior School</h2>
                    <img src={boys} alt="Senior School" />
                    <div className="descriptio-text">
                    <p>
                        Senior school, sometimes referred to as high school or secondary
                        school, is the final stage of formal education before entering
                        college or the workforce. It typically covers grades 9 through 12
                        and offers a wide range of academic and elective courses to prepare
                        students for their future endeavors.
                    </p>
                    </div>
                    
                </div>
            </div>
            <Footer/>
            <DownFooter/>
        </>
    );
}

const Section = ({ title, imageSrc, description }) => {
    return (
      <div className="section">
        <h2>{title}</h2>
        <img src={imageSrc} alt={title} />
        <p>{description}</p>
      </div>
    );
  };
export default Curriculum;