import React from "react";
import './Facilities.css';

// import images

import bus from '../Components/imgs/Bus.jpg'
import Bio from '../Components/imgs/Bio.jpg'
import Chemistry from '../Components/imgs/Chemistry.jpg'
import Physics from '../Components/imgs/Physics.jpg'
import Computer from '../Components/imgs/computer Center.jpg'



import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";

const Facilities = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            <div className="text-center pt-5 text-primary-emphasis">
                <h2>Facilities</h2>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2>Biology Lab</h2>
                        <p>
                            The Biology Lab at Pawa Public School is a dynamic space where students delve into the wonders of life sciences through hands-on exploration
                            and experimentation. Equipped with cutting-edge laboratory equipment and specimens, the lab provides an immersive learning environment where
                            students can observe, analyze, and dissect various biological specimens. From studying cellular structures under microscopes to conducting
                            experiments on DNA extraction, students engage in a wide range of activities that deepen their understanding of living organisms and biological
                            processes. Under the guidance of experienced teachers, students develop essential laboratory skills such as hypothesis formation,
                            data collection, and analysis, preparing them for future scientific endeavors. Beyond academic learning, the Biology Lab fosters curiosity,
                            critical thinking, and a passion for discovery, inspiring the next generation of biologists, researchers, and innovators.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={Bio} alt="School" />
                    </div>
                </div>
            </div>

            <div className="school-description">
                <div className="container">
                    <div className="image1">
                        <img src={Chemistry} alt="School" />
                    </div>
                    <div className="text">

                        <h2>Chemistry Lab</h2>
                        <p>
                            The Chemistry Lab at Pawa Public School is a captivating environment where students immerse themselves in the fascinating world of
                            chemical reactions and discoveries. Equipped with state-of-the-art equipment, safety measures, and an array of chemical compounds,
                            the lab serves as a dynamic arena for hands-on experimentation and exploration. Students engage in a variety of experiments,
                            ranging from simple reactions to complex synthesis processes, under the careful guidance of experienced instructors.
                            From observing color changes to measuring reaction rates, students gain practical experience that reinforces theoretical
                            knowledge learned in the classroom. The lab environment encourages collaboration, critical thinking, and problem-solving
                            skills as students work together to analyze results and draw conclusions.
                        </p>
                    </div>
                </div>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2>Physics Lab</h2>
                        <p>
                            The Physics Lab at Pawa Public School is an exhilarating space where students delve into the fundamental principles
                            of the universe through hands-on experimentation and exploration. Equipped with advanced equipment, precise measurement tools,
                            and experimental setups, the lab offers a dynamic environment for students to observe, analyze, and manipulate physical
                            phenomena. From investigating the laws of motion with pendulums to exploring the principles of electromagnetism through
                            circuits and coils, students engage in a wide range of experiments that deepen their understanding of the natural world.
                            Under the guidance of skilled instructors, students develop essential laboratory skills such as data collection, analysis,
                            and interpretation, honing their scientific inquiry abilities. The Physics Lab fosters a spirit of curiosity, innovation,
                            and problem-solving as students collaborate, hypothesize, and test their theories in real-time.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={Physics} alt="School" />
                    </div>
                </div>
            </div>

            <div className="school-description">
                <div className="container">
                    <div className="image1">
                        <img src={Computer} alt="School" />
                    </div>
                    <div className="text">

                        <h2>Computer Center</h2>
                        <p>
                            The Computer Center at Pawa Public School stands as a gateway to the digital realm, offering students a dynamic space to
                            explore the boundless possibilities of technology. Equipped with cutting-edge computers, high-speed internet connectivity,
                            and a variety of software applications, the center serves as a hub for digital literacy and skill development.
                            Here, students engage in a diverse range of activities, from learning programming languages and mastering software
                            applications to exploring the realms of robotics and artificial intelligence. Under the guidance of knowledgeable instructors,
                            students harness the power of technology to solve complex problems, unleash their creativity, and develop critical thinking skills.
                            The Computer Center fosters collaboration and innovation as students work together on projects, coding challenges, and multimedia
                            presentations. Beyond academic pursuits, the center cultivates digital citizenship, teaching students to navigate the digital
                            landscape responsibly and ethically. With its emphasis on innovation, collaboration, and digital literacy, the Computer Center
                            at Pawa Public School prepares students to thrive in the digital age and become leaders in the ever-evolving world of technology.
                        </p>
                    </div>
                </div>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2 className="text-primary-emphasis">Bus Facility</h2>
                        <p>
                            At Pawa Public School, our bus facility ensures a safe, convenient, and reliable transportation solution for our students.
                            Our fleet of buses is well-maintained and equipped with modern amenities to provide a comfortable journey to and from school.
                            With experienced and trained drivers at the helm, we prioritize the safety and security of our students during their commute.
                            Our bus routes are carefully planned to cover various neighborhoods, ensuring accessibility for all students.
                            We strive to create a positive and enjoyable travel experience for our students, fostering
                            camaraderie and a sense of community among passengers. At Pawa Public School, our bus facility is more than just transportation — it's a
                            vital component of our commitment to providing a holistic and supportive learning environment for our students.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={bus} alt="School" />
                    </div>
                </div>
            </div>
            <Footer />
            <DownFooter />
        </>
    )

}

export default Facilities;