import React from "react";

import './DownFooter.css';

const DownFooter= ()=>{
    

    const LinkedInLink = () => {
        return (
            
                <a id="copy" href="https://www.linkedin.com/in/anandeshwar-singh-a506361aa/" target="_blank" rel="noopener noreferrer">
                    Anandeshwar Singh
                </a>
            
        
        );
    };

    return(
        <>
        <div className="copyright">
            <p>
                Copyright &#169; 2024 Pawa Public Secondary School. All Rights Reserved. | Design and developed by G Soft Solution
                {/* <LinkedInLink /> */}
            </p>
        </div>
        </>
    )
}
export default DownFooter;