import React from "react";

// import component

import Banner1 from '../Components/Banner1';
import Header from '../Components/Header1';
import Slider from "../Components/Slider";
import Welcome from "../Components/Welcome";
import Schooldes from "../Components/Schooldes";
import Contactus from "../Components/Contactus";
import Footer from "../Components/Footer";
import Message from "../Components/Message";
import Gallery from "../Components/Gallery";
import DownFooter from "../Components/DownFooter";



//import Pages


const Home = ()=>{
    return(
        <>
        <Banner1/>
        <Header/>
        <Slider/>
        <Welcome/>
        <Schooldes/>
        <Message/>
        <Gallery/>
        <Footer/>
        <DownFooter/>
        </>
    )
}

export default Home;