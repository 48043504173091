import React from "react";
import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";
import ClassRoom from '../Components/imgs/ClassRoom.jpg'
import DegitalClass from './imgs/DegitalClass.jpg'

const Classroom = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2>Class Room</h2>
                        <p>The classroom at Pawa Public School is a vibrant space where learning comes to life.
                            Each classroom is carefully curated to foster an environment of creativity, collaboration, and discovery.
                            From colorful bulletin boards adorned with student work to cozy reading corners filled with books, every corner of the
                            classroom is designed to inspire young minds. The desks are arranged to encourage interaction and teamwork, while the
                            walls are adorned with educational posters and charts that serve as visual aids for learning. At the front of the room,
                            the teacher's desk serves as a focal point, where educators impart knowledge and guidance to their eager students.
                            The classroom buzzes with energy as students engage in discussions, participate in hands-on activities, and delve into their studies.
                            With ample natural light streaming in through the windows and the hum of learning filling the air, the physical classroom at Pawa Public
                            School is truly a place where academic excellence thrives.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={ClassRoom} alt="School" />
                    </div>
                </div>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="image1">
                        <img src={DegitalClass} alt="School" />
                    </div>
                    <div className="text">
                        <h2>Digital Class Room</h2>
                        <p>
                            In the digital classroom of Pawa Public School, education transcends traditional boundaries, 
                            bringing the world of learning to students' fingertips. Equipped with state-of-the-art technology, 
                            each digital classroom is a gateway to a vast array of educational resources and interactive tools. 
                            Students engage with course materials through digital platforms, accessing e-books, videos, 
                            and simulations that enhance their understanding of complex concepts. Interactive whiteboards serve as 
                            dynamic canvases for teachers to deliver engaging lessons, incorporating multimedia elements to captivate students' 
                            attention. Through video conferencing and online collaboration tools, students connect with peers and educators beyond 
                            the confines of the physical classroom, fostering a global learning community. In this digital landscape, 
                            learning becomes personalized, adaptable, and accessible, empowering students to explore, create, 
                            and succeed in an ever-evolving world.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
            <DownFooter />
        </>
    )
}
export default Classroom