import React from "react";

import './Information.css'

import Banner1 from "./Banner1";
import Header1 from "./Header1"
import Footer from "./Footer";
import DownFooter from "./DownFooter";

const Information = ()=>{
    return(
        <>
            <Banner1/>
            <Header1/>
            <div className="activities-page">

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">School Hours</h1>
                    <p>
                        The Academic Year start from April and end in March.
                        <br/>
                        <br/>
                        In between there is Three exams Quaterly exam, Half yearly exam and Annual exam
                        <br/>
                        <br/>
                        The School gives three long holidays during the year.
                        
                        <div className="containe">
                            <div className= "holidaycss">
                                <p >
                                    End of Term vacation:
                                    About 2 weeks
                                </p>
                                <p >
                                    Winter vacation :
                                    About 2 weeks
                                </p>
                                <p >
                                    Summer vacation:
                                    About 6 weeks       
                                </p>
                            </div>
                        </div>
                        <br/>
                        The dates of opening and closing of school must be strictly observed. 
                        If a child leaves early at the end of the term, her/his report will be withheld until the following term.
                        <br/>
                        <br/>
                        Parents are informed and are especially asked to note that, in the interest of their children and the discipline 
                        of the school, it is absolutely imperative that they strictly abide by the opening and closing dates of the school.
                        On no account can “Special Cases” be considered for permission to break the rule.
                        No child will leave school before the beginning of a holiday (Diwali, Christmas & Summer vacations) and absolute punctuality is 
                        necessary on the re- opening of the school. Students not returning to school within a month after the holidays, 
                        are liable to have their names struck off the rolls. If a child has to leave early or return late due to certain 
                        unavoidable circumstances, the Principal must be intimated in writing.
                        <br/>
                        <br/>

                        <h5>School timings: (Monday to Saturday)</h5>
                        <div className="containe">
                            <div className= "holidaycss">
                                <p >
                                    Nurseary, LKG and UKG : 
                                    09.00 a.m. to 02.00 p.m.
                                </p>
                                <p >
                                    Standard I to X :
                                    09.00 a.m. to 02.30 p.m.
                                </p>
                                
                            </div>
                        </div>
                        <br/>
                        <br/>
                        Pupils are asked to arrive at school 15 minutes before school starts. All members of staff are on duty for 15 minutes before 
                        and after school. Please make sure your child arrives on time. This is very important because good habits need to be 
                        established early and we want all children to start the day well. Parents are requested to fetch their children on time. 
                        We are not legally responsible for your children before or after the pre-stipulated times.
                        <br/>
                        <br/>
                        <h5>School Office Hours</h5>
                        <div className="containe">
                            <div className= "holidaycss">
                                <p >
                                    Monday to Friday:
                                    09:30 a.m. to 03:30 p.m.
                                </p>
                                <p >
                                    Saturday:
                                    09:00 a.m. to 12:00 noon.
                                </p>
                                
                            </div>
                        </div>
                        <br/>
                        <br/>
                        * The Principal will meet parents between 10:30 a.m. and 02.00 p.m. only by prior appointment.
                        <br/>
                        <br/>
                    </p>
                    
                </div> 
            </div>
            
            
                
            <Footer/>
            <DownFooter/>
        </>
    )
}
export default Information;