import React from "react";

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";


import cric from '../Components/imgs/Cricket.jpg'
import basball from '../Components/imgs/Basket_ball.jpg'
import bollye from '../Components/imgs/bollyeball.jpg'
import race from '../Components/imgs/Race_Track.jpg'
import jum from '../Components/imgs/high jump.jpg'
import badminton from '../Components/imgs/Badminton.jpg'

const Playground = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            <div className="text-center pt-5 text-primary-emphasis">
                <h2>Playground</h2>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2>Cricket</h2>
                        <p>
                            Pawa Public Secondary School boasts top-notch facilities tailored to nurture budding cricketers and promote the spirit of cricket among its students.
                            With a dedicated focus on providing comprehensive resources for cricket enthusiasts, the school offers a range of facilities designed to facilitate training,
                            practice, and competitive matches. range of activities that deepen their understanding of living organisms and biological
                            t the heart of the school's cricket program lies its meticulously maintained cricket ground.
                            Pawa Public Secondary School provides a well-stocked equipment shed housing a wide array of cricketing gear and accessories.
                            In essence, Pawa Public Secondary School is committed to fostering a rich cricketing culture and providing aspiring cricketers with the necessary infrastructure to thrive in the sport.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={cric} alt="School" />
                    </div>
                </div>
            </div>

            <div className="school-description">
                <div className="container">
                    <div className="image1">
                        <img src={basball} alt="School" />
                    </div>
                    <div className="text">

                        <h2>Basketball</h2>
                        <p>
                            Pawa Public Secondary School prides itself on offering top-tier facilities tailored to cultivate a passion for basketball and
                            develop the skills of aspiring athletes. With a strong commitment to promoting teamwork, athleticism, and sportsmanship,
                            the school provides a range of facilities dedicated to the sport of basketball.
                            The school offers a comprehensive range of training equipment and resources to support basketball development.
                            From basketballs and training cones to agility ladders and resistance bands, students have access to everything they
                            need to enhance their skills, improve their fitness levels, and refine their technique.
                        </p>
                    </div>
                </div>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2>Volleyball</h2>
                        <p>
                            Pawa Public Secondary School boasts comprehensive facilities dedicated to nurturing a thriving volleyball culture among its students.
                            Central to the school's volleyball program is a state-of-the-art volleyball court featuring regulation dimensions and premium-quality
                            playing surface. The court serves as the focal point for volleyball activities, accommodating both practice sessions and competitive
                            matches. Surrounding the court are spectator seating arrangements, providing an immersive viewing experience during matches and
                            tournaments. Additionally, the school offers a range of training equipment, including volleyballs, nets, and training aids, to
                            support skill development and training drills.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={bollye} alt="School" />
                    </div>
                </div>
            </div>

            <div className="school-description">
                <div className="container">
                    <div className="image1">
                        <img src={race} alt="School" />
                    </div>
                    <div className="text">

                        <h2>Race</h2>
                        <p>
                            Pawa Public Secondary School is dedicated to fostering a culture of athleticism and healthy competition, offering
                            comprehensive facilities to support various racing events. The school features a well-maintained track and field facility
                            equipped with standard sprinting lanes, long jump pits, and throwing areas. This facility serves as the venue for
                            inter-house track meets.
                            Under the guidance of experienced coaches, students receive specialized training programs tailored to their
                            individual strengths and goals, helping them develop their racing skills and reach their full potential.
                        </p>
                    </div>
                </div>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="text">

                        <h2>High Jump & Long Jump</h2>
                        <p>
                            Pawa Public Secondary School is equipped with dedicated facilities to support high jump and long jump training and competitions.
                            The school's athletics complex includes specialized areas for both high jump and long jump events, featuring state-of-the-art
                            landing pits, runways, and equipment.
                            he high jump area is outfitted with a cushioned landing pit and adjustable high jump standards, allowing athletes to practice
                            and compete in this technical event safely and effectively. The landing pit is designed to absorb impact,
                            ensuring the safety of jumpers during training and competition.
                        </p>
                    </div>
                    <div className="image1">
                        <img src={jum} alt="School" />
                    </div>
                </div>
            </div>
            <div className="school-description">
                <div className="container">
                    <div className="image1">
                        <img src={badminton} alt="School" />
                    </div>
                    <div className="text">

                        <h2>Badminton</h2>
                        <p>
                            Pawa Public Secondary School boasts comprehensive facilities dedicated to promoting the sport of badminton among
                            its students. The school features a modern badminton court equipped with high-quality flooring, regulation markings,
                            and adjustable nets, providing an ideal setting for both recreational play and competitive matches. Additionally,
                            the school offers a range of badminton equipment, including shuttlecocks, racquets, and training aids, to support
                            skill development and training drills. Under the guidance of experienced coaches, students receive expert instruction,
                            technical guidance, and personalized training programs to enhance their badminton skills and strategic understanding.
                            Pawa Public Secondary School actively participates in inter-school badminton tournaments, providing students with valuable
                            opportunities to showcase their talent, compete at a higher level, and foster a spirit of sportsmanship and camaraderie. 
                            Through its commitment to providing top-tier facilities, experienced coaching staff, and competitive opportunities, the 
                            school aims to instill a lifelong love for badminton and empower students to excel both on and off the court.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
            <DownFooter />
        </>
    )
}
export default Playground;