import React from "react";
import axios from 'axios';
import { useState } from "react";
import { useEffect } from "react";

import Banner1 from "../Components/Banner1"
import Header1 from "../Components/Header1";
import Footer from "../Components/Footer";
import DownFooter from "../Components/DownFooter";
import './Base.css'


const Base = ({ children }) => {
    const [form, setForm] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        class: '',
        section: '',
        mobileNumber: '',
        religion: '',
        registrationNumber: '',
        aadharNumber: '',
        dateOfBirth: '',
        email: '',
        fatherName: '',
        fatherMobileNumber: '',
        fatherQualification: '',
        fatherOccupation: '',
        fatherEmail: '',
        motherName: '',
        motherMobileNumber: '',
        motherQualification: '',
        motherOccupation: '',
        motherEmail: '',
        previousSchoolName: '',
        previousSchoolBoardName: '',
        previousClass: '',
        examResult: '',
        marksObtained: '',
        totalMarks: '',
        percentage: '',
        streetAddress: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        gender: '',
        
        studentPhoto: null,
        transferCertificate: null,
        characterCertificate: null,
        previousReportCard: null,
        dobCertificate: null,
        aadharCard: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(form);
    };

    const [category, SetCategory] = useState([]);
    useEffect(() => {
        const getcategory = async () => {
            const res = await fetch('http://localhost:8081/Base');
            const getdata = await res.json();
            SetCategory(getdata);
            console.log(getdata);
        }
        getcategory();
    })


    return (
        <>
            <div >
                <Banner1 />
            </div>
            <div>
                <Header1 />
                {children}
            </div>
            <div className="basemandive">
                <form className="baseform" onSubmit={handleSubmit}>
                    <h1 className="baseh1">Student Information</h1>
                    <label>
                        First Name:
                        <input type="text" name="firstName" value={form.firstName} onChange={handleChange} />
                    </label>
                    <label>
                        Middle Name:
                        <input type="text" name="middleName" value={form.middleName} onChange={handleChange} />
                    </label>
                    <label>
                        Last Name:
                        <input type="text" name="lastName" value={form.lastName} onChange={handleChange} />
                    </label>
                    <label>
                        Class:
                        <input type="text" name="class" value={form.class} onChange={handleChange} />
                    </label>
                    <label>
                        Section:
                        <input type="text" name="section" value={form.section} onChange={handleChange} />
                    </label>
                    <label>
                        Gender:
                        <select name="gender" value={form.gender} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </label>
                    <label>
                        Mobile No.:
                        <input type="tel" name="mobileNumber" value={form.mobileNumber} onChange={handleChange} />
                    </label>
                    <label>
                        Religion:
                        <input type="text" name="religion" value={form.religion} onChange={handleChange} />
                    </label>
                    <label>
                        Registration No.:
                        <input type="text" name="registrationNumber" value={form.registrationNumber} onChange={handleChange} />
                    </label>
                    <label>
                        Aadhar No.:
                        <input type="text" name="aadharNumber" value={form.aadharNumber} onChange={handleChange} />
                    </label>
                    <label>
                        Date of Birth:
                        <input type="date" name="dateOfBirth" value={form.dateOfBirth} onChange={handleChange} />
                    </label>
                    <label>
                        Email:
                        <input type="email" name="email" value={form.email} onChange={handleChange} />
                    </label>

                    <h1 className="baseh1">Father's Details</h1>
                    <label>
                        Father's Name:
                        <input type="text" name="fatherName" value={form.fatherName} onChange={handleChange} />
                    </label>
                    <label>
                        Father's Mobile No.:
                        <input type="tel" name="fatherMobileNumber" value={form.fatherMobileNumber} onChange={handleChange} />
                    </label>
                    <label>
                        Father's Qualification:
                        <input type="text" name="fatherQualification" value={form.fatherQualification} onChange={handleChange} />
                    </label>
                    <label>
                        Father's Occupation:
                        <input type="text" name="fatherOccupation" value={form.fatherOccupation} onChange={handleChange} />
                    </label>
                    <label>
                        Father's Email:
                        <input type="email" name="fatherEmail" value={form.fatherEmail} onChange={handleChange} />
                    </label>

                    <h1 className="baseh1">Mother's Details</h1>
                    <label>
                        Mother's Name:
                        <input type="text" name="motherName" value={form.motherName} onChange={handleChange} />
                    </label>
                    <label>
                        Mother's Mobile No.:
                        <input type="tel" name="motherMobileNumber" value={form.motherMobileNumber} onChange={handleChange} />
                    </label>
                    <label>
                        Mother's Qualification:
                        <input type="text" name="motherQualification" value={form.motherQualification} onChange={handleChange} />
                    </label>
                    <label>
                        Mother's Occupation:
                        <input type="text" name="motherOccupation" value={form.motherOccupation} onChange={handleChange} />
                    </label>
                    <label>
                        Mother's Email:
                        <input type="email" name="motherEmail" value={form.motherEmail} onChange={handleChange} />
                    </label>

                    <h1 className="baseh1">Previous School Information</h1>
                    <label>
                        Previous School Name:
                        <input type="text" name="previousSchoolName" value={form.previousSchoolName} onChange={handleChange} />
                    </label>
                    <label>
                        School Board Name:
                        <input type="text" name="previousSchoolBoardName" value={form.previousSchoolBoardName} onChange={handleChange} />
                    </label>
                    <label>
                        Previous Class Attended:
                        <input type="text" name="previousClass" value={form.previousClass} onChange={handleChange} />
                    </label>
                    <label>
                        Exam Result (Pass/Fail):
                        <select name="examResult" value={form.examResult} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="pass">Pass</option>
                            <option value="fail">Fail</option>
                        </select>
                    </label>
                    <label>
                        Marks Obtained:
                        <input type="number" name="marksObtained" value={form.marksObtained} onChange={handleChange} />
                    </label>
                    <label>
                        Total Marks:
                        <input type="number" name="totalMarks" value={form.totalMarks} onChange={handleChange} />
                    </label>
                    <label>
                        Percentage:
                        <input type="number" name="percentage" value={form.percentage} onChange={handleChange} step="0.01" />
                    </label>

                    <h1 className="baseh1" >Document</h1>
                    <label>
                        Photo of Student:
                        <input type="file" name="studentPhoto" onChange={handleChange} />
                    </label>
                    <label>
                        Transfer Certificate:
                        <input type="file" name="transferCertificate" onChange={handleChange} />
                    </label>
                    <label>
                        Character Certificate:
                        <input type="file" name="characterCertificate" onChange={handleChange} />
                    </label>
                    <label>
                        Previous School Report Card:
                        <input type="file" name="previousReportCard" onChange={handleChange} />
                    </label>
                    <label>
                        Date of Birth Certificate:
                        <input type="file" name="dobCertificate" onChange={handleChange} />
                    </label>
                    <label>
                        Aadhar Card:
                        <input type="file" name="aadharCard" onChange={handleChange} />
                    </label>


                    <h1 className="baseh1">Address Information</h1>
                    <label>
                        Street Address:
                        <input type="text" name="streetAddress" value={form.streetAddress} onChange={handleChange} />
                    </label>
                    <label>
                        City:
                        <input type="text" name="city" value={form.city} onChange={handleChange} />
                    </label>
                    <label>
                        State:
                        <input type="text" name="state" value={form.state} onChange={handleChange} />
                    </label>
                    <label>
                        Zip Code:
                        <input type="text" name="zipCode" value={form.zipCode} onChange={handleChange} />
                    </label>


                    <button className="button23" type="submit">Submit</button>
                </form>
            </div>
            <div>
                <Footer />
                <DownFooter />
            </div>
        </>
    )
}
export default Base;