import React from "react";
import './Slider.css'
import pic from "./imgs/Img_1.JPG";
import pic2 from "./imgs/Img_2.jpg";
import pic3 from "./imgs/img_3.jpg";


function Slider(){
    return(
        <>
        {/* class="carousel slide carousel-fade" */}
        <div id="carouselExampleFade" class="carousel slide carousel"> 
          <div class="carousel-inner">
            <div class="carousel-item active">
            <img src={pic} className="w-100 pic-height" alt="..." />
            </div>
            <div class="carousel-item">
            <img src={pic2} className="w-100 pic-height"  />
            </div>
            <div class="carousel-item">
            <img src={pic3} className="w-100 pic-height" alt="..." />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </>
    );
}
export default Slider;
