import React from "react";

import './PrePrimary.css'

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";




const Secondary = ()=>{
    return(
        <>
            <Banner1/>
            <Header1/>
            <div className="activities-page">

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Secondary School Fazilnagar Badahara</h1>
                    <p>
                        Centrally located Fazilnagar campus at Badahara, Pawa Public Secondary School has successfully established itself 
                        as a renowned U.P. Board school in Badahara.
                        The school follows the U.P. Board curriculum that is tailored to the needs of this generation and aims at the 
                        holistic development of children.
                        <br/>
                        <br/>
                        <br/>
                        The School provides a number of extra-curricular activities within the campus. Pawa Public Secondary School, 
                        Fazilnagar possesses state-of-the-art 
                        infrastructure, with a library, football field, lawn tennis court, sand pit, outdoor play equipment, 
                        separate labs for Physics, Chemistry, Biology, Computer studies, transport facility, music room, dance class
                         thus allowing for the all-round development of children.
                        <br/>
                        <br/>
                        <h5>labs</h5>
                        <ul>
                            <li>
                                Physics lab
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Chemistry lab
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Biology lab
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Computer center
                            </li>
                        </ul>
                        
                    </p> 
                </div>

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Criteria for Admission into Secondary</h1>
                    <p>
                        Admission forms are available on the internet. However, application forms should be collected from the school office.
                         Submission of an application form does not guarantee admission. Admissions into the Primary Section are made against
                          vacancies that arise at the end of the academic year. In case of a new admission, the latest school report must be
                           submitted along with the application form. 
                           
                        <br/>
                        <br/>
                        *Admission is fee in Pre-Nursery and Nursery.
                        <br/>
                        <br/>
                        <h5>Required Documents</h5>
                        <ul>
                            <li>
                                It is mandatory to produce the student’s Birth Certificate at the time of admission. 
                                In case of new admissions to Class VIII and above, the parents must submit a Transfer Certificate from 
                                the previous school.
                            </li>
                        </ul>
                        <br/>
                        <br/>
                    </p> 
                </div>
                <div className="note">
                        <div>
                        <h5>PLEASE NOTE</h5>
                        <p>
                            -  There is a entrance test for new student.
                            <br/>
                            <br/>
                            -  Admission will be purely based on vacancies in the respective classes and Entrance Test.
                            <br/>
                            <br/>
                            <ul>
                                <li>
                                    Above 60% - Free Admission
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Above 50% to 60% - Discount of 50% on Admission Fee
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Above 40% to 49% - Discount 25% on Admission Fee
                                </li>
                            </ul>
                        </p>
                        </div>
                    </div>
                
            </div>
            <Footer/>
            <DownFooter/>
        </>
    )
}
export default Secondary;