import React from "react";


import pic2 from './imgs/Img_2.jpg'
import pic3 from './imgs/img_3.jpg'
import pic4 from './imgs/logo.png'
import pic5 from './imgs/Principal.jpeg'
import pic6 from './imgs/extra_cari.jpeg'
import pic7 from './imgs/pre.jpg'
import './Gallery.css';

import img1 from './imgs/one.JPG'
import img2 from './imgs/two.JPG'
import img3 from './imgs/three.JPG'
import img4 from './imgs/four.JPG'
import img5 from './imgs/five.JPG'
import img6 from './imgs/six.JPG'
import img7 from './imgs/seven.JPG'
import img8 from './imgs/eight.JPG'




const Gallery = ()=>{
    
            
    return(
        <>

            <div className="text-center pt-5 text-primary-emphasis">
                    <h2>Gallery</h2>
            </div>
            <div className="slider">
                <div className="slide-track">
                    <div className="slidee">
                        <img src={img1}/>
                    </div>
                    <div className="slidee">
                        <img src={img2}/>
                    </div>
                    <div className="slidee">
                        <img src={img3}/>
                    </div>
                    <div className="slidee">
                        <img src={img4}/>
                    </div>
                    <div className="slidee">
                        <img src={img5}/>
                    </div>
                    <div className="slidee">
                        <img src={img6}/>
                    </div>
                    <div className="slidee">
                        <img src={img7}/>
                    </div>
                    <div className="slidee">
                        <img src={img8}/>
                    </div>
                    

                    {/* duble it same slider */}
                    <div className="slidee">
                        <img src={img1}/>
                    </div>
                    <div className="slidee">
                        <img src={img2}/>
                    </div>
                    <div className="slidee">
                        <img src={img3}/>
                    </div>
                    <div className="slidee">
                        <img src={img4}/>
                    </div>
                    <div className="slidee">
                        <img src={img5}/>
                    </div>
                    <div className="slidee">
                        <img src={img6}/>
                    </div>
                    <div className="slidee">
                        <img src={img7}/>
                    </div>
                    <div className="slidee">
                        <img src={img8}/>
                    </div>
                    

                </div>

            </div>

        </>
    )
    
}
export default Gallery;