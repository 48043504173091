import React from "react";
import ban from "./imgs/Banner.png"
import './Banner1.css';

const Banner1 = () =>{
    return(
        <>

            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="10000">
                    <img id="banner" src={ban} className="d-block w-100" alt="..." />
                    </div>
                </div>
            </div>
            {/* <div>
              <img src={ban} alt="..." />
            </div> */}
        </>
    );
}
export default Banner1