import React from "react";
import './Schooldes.css'
import des from './imgs/Schooldes.jpg'


const Schooldes = ()=>{
    return(
        <>
            <div className="school-description">
                <div className="container1">
                    <div className="text">
                        <h5 className="text-primary-emphasis">About</h5>
                        <h2>Pawa Public Sec. School</h2>
                        <p>Pawa Public Secondary School is committed to providing quality education and nurturing the holistic development of students.
                            Pawa public Secondary School, with over one decade of experience, has excelled in imparting quality education to 
                            students entrusted to its care.
                        </p>
                    </div>
                    <div className="image">
                        <img src={des} alt="School" />
                    </div>
                </div>
            </div>
        </>
    )

}
export default Schooldes;