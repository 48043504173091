import React from "react";

import './AdmissionProcess.css'

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";




const AdmissionProcess = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            <div className="activities-page">

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Admission Process</h1>
                    <p>
                        Admission for the Academic Year 2024-2025 are Open !
                        <br />
                        <br />
                        The Application Form for admission to Pre-Nursery, Nursery, Preparatory Class, and Class 1 will be available on our website from 23 February 2024.
                        <br />
                        <br />
                        The Application Form for admission to Classes 2 to 9 will be available on our website from 10th January 2024.
                        <br />
                        <br />
                        Parents must visit the school office to submit the printed Application Form along with the documents on any weekday.
                        <br />
                        <br />
                        Office timings: Monday to Saturday - 10:00 a.m. to 03:00 p.m.
                    </p>
                </div>

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">ADMISSION DOCUMENTS</h1>
                    <p>
                        The following documents (photocopies) need to be submitted along with the printed Application Form:
                        <br />
                        <br />
                        <ul>
                            <li>
                                A copy of the Birth Certificate of the child (self-attested). No Application Form will be accepted without a copy of the child’s Birth Certificate.
                            </li>
                        </ul>
                        <ul>
                            <li>
                                A copy of the Educational Certificates of both the Parents (self-attested).
                            </li>
                        </ul>
                        <ul>
                            <li>
                                A copy of the proof of residence (Electricity bill/ Telephone bill/ AADHAR card/ Passport/ Election Card - any one).
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Photographs of both the parents and the child must be pasted on the printed Application Form (one each).
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Report card issued by the previous school of the last class attended.
                            </li>
                        </ul>
                        <br />
                        *Originals must be shown to the appointed school authority for verification.
                        <br />
                        <br />
                        An amount of ₹100 (non-refundable) will need to be paid at the time of submission of the
                        printed Application Form for the Prospectus and Processing Fee.
                        <br />
                    </p>
                    <div className="note">
                        <div>
                            <h5 className="text-danger">PLEASE NOTE</h5>
                            <p>
                                <div>
                                    Filling out the Application Form does not guarantee admission.
                                </div>
                                <div>
                                    Admission will be purely based on vacancies in the respective classes.
                                </div>
                                <div>
                                    Age requirement of the child:
                                    <ul>
                                        <li>
                                            Minimum 3 years and up to 4 years as of 30th September 2024 for Pre-Nursery
                                            and corresponding age for Nursery and Preparatory, respectively.
                                        </li>
                                    </ul>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <DownFooter />
        </>
    )
}
export default AdmissionProcess;