    import React from "react";
    import './Contect1.css';



    const Contect1 = () => {
        return (
            <>
                <div className="school-container">

                    {/* School Information Section (30%) */}
                    <div className="school-info-section">
                        <h2>Get In Touch</h2>
                        <p><strong>Name:</strong> Pawa Public Secondary School</p>
                        <p><strong>Address:</strong> Badahara Fazilnagar Kushinagar 274401</p>
                        <p><strong>Contact:</strong> Phone: +91 8400634501</p>
                        <p><strong>Email:</strong> Email: pawapublic12@gmail.com</p>
                        {/* <p><strong>About:</strong> Brief description of the school...</p>
                        Add more information as needed */}
                    </div>

                    {/* Google Maps Section (70%) */}
                        <h2>Find Us</h2>
                    <div className="google-maps-section">
                        {/* Google Maps iframe embed */}
                        <iframe
                            title="School Location"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                            src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14263.160100312658!2d84.0399415!3d26.6552046!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3993bd9371a20cc1%3A0xd18670254c007b34!2sPawa%20Public%20School!5e0!3m2!1sen!2sin!4v1711476901699!5m2!1sen!2sin`}
                            allowFullScreen
                        ></iframe>
                    </div>


                </div>
            </>
        )
    }

    export default Contect1;