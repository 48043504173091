import React from "react";

import './PrePrimary.css'

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";




const Primary = ()=>{
    return(
        <>
            <Banner1/>
            <Header1/>
            <div className="activities-page">

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Primary School Fazilnagar Badahara</h1>
                    <p>
                        Centrally located Fazilnagar campus at Badahara, Pawa Public Secondary School has successfully established itself 
                        as a renowned U.P. Board school in Badahara. The Mahatama Budha Sewa Samiti of Institutes with Purple Properties started Pawa Public School 
                        at Badahara in 2010. It has emerged as one of the best activities and academics-based schools in Fazilnagar, following the CBSE 
                        syllabus. It is regarded as one of the best schools in Fazilnagar with classes from Pre-Nursery to Std X. The school right from 
                        Pre-Nursery to Std X focuses on the overall intellectual and physical growth of the child. The Primary Section from Std I-V 
                        aims at teaching children language skills, computation skills, as well as social skills through classroom teaching and 
                        interactive activities. Pawa Public Secondary School allows the little ones to develop critical thinking and logical judgement 
                        and encourages them to appreciate the dignity of work. Children have many opportunities in PPS to express themselves 
                        through drawing, painting, collage and model making. They are encouraged to work with a partner or as part of a group. 
                        Children learn and sing new songs and rhymes, participate in cultural displays and become skilled in the nuances of Speech 
                        and Drama. These activities instill a sense of confidence in each child at an early age.
                        <br/>
                        <br/>
                        <br/>
                        Nowadays, parents are very conscious about their PPS’s School Admission in Fazilnagar. In such a scenario, PPS provides great 
                        opportunities for parents looking for a good school. The school admission in Fazilnagar begins from the month of November and 
                        PPS has a convenient online process for the same.
                        <br/>
                        <br/>
                        
                    </p> 
                </div>

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Criteria for Admission into Primary</h1>
                    <p>
                        Admission forms are available on the internet. However, application forms should be collected from the school office.
                         Submission of an application form does not guarantee admission. Admissions into the Primary Section are made against
                          vacancies that arise at the end of the academic year. In case of a new admission, the latest school report must be
                           submitted along with the application form. 
                           {/* If the school authority finds the earlier report excellent or 
                           the candidate is from an I.C.S.E. school, then he/she may be granted admission without an entrance test. */}
                        <br/>
                        <br/>
                        Admission is fee in Pre-Nursery and Nursery.
                        <br/>
                        <br/>
                        <h5>Age Criteria</h5>
                        <ul>
                            <li>
                                The student should be between 5 to 7 years old for admission to Std. I.
                            </li>
                        </ul>
                        <h5>Required Documents</h5>
                        <ul>
                            <li>
                                It is mandatory to produce the child’s Birth Certificate at the time of admission. 
                                In case of new admissions to Class I and above, the parents must submit a Transfer Certificate from 
                                the previous school.
                            </li>
                        </ul>
                        <br/>
                        <br/>
                    </p> 
                </div>
                <div className="note">
                        <div>
                        <h5 className="text-danger">PLEASE NOTE</h5>
                        <p>
                            -  There is a entrance test for new student.
                            <br/>
                            <br/>
                            -  Admission will be purely based on vacancies in the respective classes and Entrance Test.
                            <br/>
                            <br/>
                            <ul>
                                <li>
                                    Above 60% - Free Admission
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Above 50% to 60% - Discount of 50% on Admission Fee
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    Above 40% to 49% - Discount 25% on Admission Fee
                                </li>
                            </ul>
                        </p>
                        </div>
                    </div>
                
            </div>
            <Footer/>
            <DownFooter/>
        </>
    )
}
export default Primary;