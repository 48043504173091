import React from "react";
import './Progress_Promation.css'


import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";
import Ass from '../Components/imgs/Asses.jpg'
import promo from '../Components/imgs/promotion.jpeg'


const Progress_Promation = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            <div className="Progress">
                <h1 className="heading">Progress and Promotion</h1>
                <div className="school-descriptions-containe">
                    <div className="school-descriptio">
                        <h2 className="text-primary-emphasis">Assessment</h2>
                        <img src={Ass} alt="Pre-Primary School" />
                        <div className="descriptio-text">
                            <div className="Assesment">
                                <p>
                                    Assessment is a continuous process that takes place in all areas of school life.
                                </p>
                                <p>
                                    Through on-going assessment, we hope to build a complete picture of a student’s
                                    development, not only intellectually, but emotionally, socially and physically
                                </p>
                                <p>
                                    Formal testing allows us to track the progress of individual students
                                    and also helps us to identify the strengths and weaknesses across the school.
                                </p>
                                <p>
                                    There are periodic Unit Assessments and two Terminal Assessments including several units of study.
                                </p>
                                <p>
                                    Absence from one or more subjects involves the loss of grades for these subjects and excludes the student from prizes and awards. Students absent from
                                    an assessment should not expect to be re-assessed and those absent from an assessment without permission will be considered as having failed.
                                </p>
                                <p>
                                    Absence from one or more subjects involves the loss of grades for these subjects and excludes the student from prizes and awards. Students absent from
                                    an assessment should not expect to be re-assessed and those absent from an assessment without permission will be considered as having failed.
                                </p>
                                <p>
                                    Parents will receive information about the results of these assessments as part of the student’s Annual School Report.
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className="school-descriptio">
                        <h2 className="text-primary-emphasis">Promotion</h2>
                        <img src={promo} alt="Pre-Primary School" />
                        <div className="descriptio-text">
                            <div className="Assesment">
                                <p>
                                    Promotions are decided on the student’s progress throughout the year based on the periodic assessment and the terminal assessments.
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <DownFooter />
        </>
    )
}
export default Progress_Promation 