import './Footer.css'
import ban from './imgs/logo.png'
const Footer =()=>{

    return (
        <footer className="container-fluid bg-grey py-5">
            <div className="container-sm">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="logo-part">
                                    <img src={ban} className="w-50 logo-footer" />
                                    <p>Badahara, Fazilnagar, District Kushinagar</p>
                                    <p>Utter Pradesh 274401</p>
                                </div>
                            </div>
                            <div className="col-md-6 px-4">
                                <h3> Pawa Public School</h3>
                                <p>Badahara, Fazilnagar, District Kushinagar</p>
                                <a href="#" className="btn-footer"> More Info </a><br />
                                <a href="#" className="btn-footer"> Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 px-4">
                                <h6> Help us</h6>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <ul>
                                            <li> <a href="#"> Home</a> </li>
                                            <li> <a href="#"> About</a> </li>
                                            <li> <a href="#"> Service</a> </li>
                                            <li> <a href="#"> Team</a> </li>
                                            <li> <a href="#"> Help</a> </li>
                                            <li> <a href="#"> Contact</a> </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 px-4">
                                        <ul>
                                            <li> <a href="#"> Bus Faciliy</a> </li>
                                            <li> <a href="#"> Admission Process</a> </li>
                                            <li> <a href="#"> Building</a> </li>
                                            <li> <a href="#"> Facilities</a> </li>
                                            <li> <a href="#"> Refunds</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <h6> Newsletter</h6>
                                <div className="social">
                                    <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                </div>
                                <br></br>
                                    <a href="tel:+918400634501"><i className="fa fa-phone" aria-hidden="true"></i> +91 8400634501</a><br/>
                                    <a href="mailto:pawapublic12@gmail.com"><i className="fa fa-envelope" aria-hidden="true"></i> pawapublic12@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </footer>

    );
}
export default Footer;
