import React from "react";
import './Welcome.css';

const Welcome = () =>{
    return(
      
        <>
            <div className="welcome-page">
        <header>
          <h1>WELCOME TO PAWA PUBLIC SECONDARY SCHOOL</h1>
          <p>Empowering Students to Achieve Excellence</p>
        </header>
        <main>
          <section className="card1">
            <h2 className="text-primary-emphasis">Welcome Note</h2>

            <p>
            Welcome to Pawa Public Secondary School!
              
            <br/>
              Located in the serene surroundings of Badhara Fazilnagar, our school stands as a beacon of knowledge and learning in the region. 
              Established nearly a decade ago, Pawa Public Secondary School has been committed to providing quality education and holistic 
              development to its students. Affiliated with the State Board of Uttar Pradesh, we adhere to the highest educational standards, 
              ensuring that our students receive a comprehensive and enriching learning experience. At Pawa Public Secondary School, 
              we believe in nurturing young minds, fostering creativity, and instilling values that prepare our students for success in all aspects 
              of life. With dedicated faculty, state-of-the-art facilities, a supportive community, we strive to create an environment 
              where every student can thrive and achieve their full potential.
            </p>
            <br/>
            <h6 id="wep">
              Welcome to Pawa Public Secondary School – where every student shines bright!
            </h6>
          </section>
        </main>
      </div>
        </>
    );
}
export default Welcome;