import React, { useState } from "react";
import axios from 'axios';
import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";

import './Login.css'
import { useNavigate } from "react-router-dom";


const Login = () => {
    const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const navigate= useNavigate(); 
   function handleSubmit (event){
        event.preventDefault();
        axios.post('http://localhost:8081/Login', {email,password}) .then(res =>{ console.log(res)
            navigate("/base")
        })
        .catch(err => console.log(err));
    }
    return (
        <>

            <Banner1 />
            <Header1 />
            {/* <div className="LoginformClass">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Enter Email</label>
                    <input type="email" id="email" autoComplete="off" placeholder="Enter username here" required
                    onChange={e => setEmail(e.target.value)}
                    ></input>
                    <label htmlFor="pass">Enter Password</label>
                    <input type="password" id="pass" autoComplete="off" placeholder="Enter password here" required
                    onChange={e => setPassword(e.target.value)}
                    ></input>
                    <input type="submit" value="Login"></input>
                </form>
            </div> */}
            <Footer />
            <DownFooter />
        </>
    )
}
export default Login;