import React from "react";

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";

import './Achievements.css'

import found from './imgs/b.k singh copy.jpeg'
import toper from './imgs/Our_Topers.jpeg'


// import picture

import founder from "./imgs/Director.jpeg"



const Achievements = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            <div className="director-message">
                <div className="director-photo">
                    <img src={found} alt="Founder" />
                </div>
                <div className="director-box">
                    <h2>Our reverend Founder Sir</h2>
                    <p>
                        Over one decades ago, the Founding Fathers envisioned an Institution which would provide the basis of learning and development of rural communities in India. The journey from the begining till done, we hope, would do justice to their vision. Today, Pawa Public School is held in high esteem for having created a micro community which:
                    </p>
                    <p>
                        1.Respects cultural diversity and unifies all under one vision and mission. <br></br>
                        2.Is dynamic and evolving in nature, encouraging innovation.<br></br>
                        3.Develops a love for inquiry based learning through student led pedagogues.<br></br>
                        4.Promotes positive communication and healthy collaboration.<br></br>
                    </p>
                    <h4>-Late B. K. Singh</h4>
                </div>
            </div>
            

            <div className="school-toper">
                <div className="container_toper">
                    <div className="text_toper">
                        <h2 className="text-primary-emphasis">Our Topers</h2>
                    </div>
                    <div className="image_toper">
                        <img src={toper} alt="toper" />
                    </div>
                </div>
            </div>
            
            <Footer />
            <DownFooter />
        </>
    )
}
export default Achievements;