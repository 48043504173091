import React from "react";
import './HolisticLearning.css'



import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";


//images
import ext from "./imgs/extra_cari.jpeg"
import dnce from "./imgs/dance_img_1.JPG"
import dnce2 from "./imgs/dance_img_2.JPG"
import dnce3 from "./imgs/dance_img_3.JPG"
import dift1 from "./imgs/gift_img_1.JPG"
import game1 from "./imgs/game.jpeg"

const HolisticLearning = ()=>{
    return(
        <>
            <Banner1/>
            <Header1/>
            <div className="activities-page">
                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Co-Curricular Activities</h1>
                    <p>
                    Co-curricular activities are activities that complement the academic curriculum. They are often
                    designed to help students develop various skills and talents beyond traditional classroom learning.
                    These activities can include sports, music, art, drama, and more.
                    </p>
                    <div className="image-grid">
                    <img src={dnce} alt="Co-curricular activity 1" />
                    <img src={dnce2} alt="Co-curricular activity 2" />
                    <img src={dnce3} alt="Co-curricular activity 3" />
                    </div>
                </div>
                
                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Extra-Curricular Activities</h1>
                    <p>
                    Extra-curricular activities are activities that take place outside of the regular curriculum. 
                    They are typically voluntary and provide opportunities for students to explore interests, 
                    develop skills, and engage with their communities. Examples include clubs, community service, 
                    sports teams, and more.
                    </p>
                    <div className="image-grid">
                    <img src={dift1} alt="Extra-curricular activity 1" />
                    <img src={ext} alt="Extra-curricular activity 2" />
                    <img src={game1} alt="Extra-curricular activity 3" />
                    </div>
                </div>

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Value-Based Learning</h1>
                    <p>
                    At Pawa Public Secondary School, we are acutely conscious of the fact that an Education devoid of values is no education at all.
                     We are proud that our students' attitude and behavior reflects our core values of compassion, courage and commitment. 
                     Through cultural displays, sports drills, street plays and skits we bring out these values in our students. 
                     Students enthusiastically participate in social service initiatives such as contributions to old age homes, orphanages, 
                     feeding the street dwellers, etc. Initiatives such as Swachh Bharat
                     Abhiyan that are practiced regularly at school bring out the values of dignity, and respect for one’s surroundings.Our core values also reflected in our four schoolhouses - Fortitude, Noble, Integrity, and Prudence.
                    </p> 
                </div>

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Experiential Learning</h1>
                    <p>
                        At Pawa Public Secondary School, we push the boundaries of teaching-learning beyond textbooks and the four walls of the classroom. 
                        Students gain practical knowledge through live projects, based on subjects they choose. For example - students of Commercial 
                        Applications conduct a 2-day food festival in the school each year, covering all aspects of business such as finance, 
                        advertising, operations, etc. Such activities provide practical exposure to our students in understanding all the nuances 
                        in business. The students learn to take initiatives, make decisions, be accountable for the results, make mistakes and learn 
                        from them. Through such activities, our students develop valuable attributes such as teamwork, collaboration, analytical thinking, 
                        strategic planning, etc. Our inter-house competitions provide a platform for healthy competition via different tasks and 
                        competitions throughout the year. This develops a winning attitude in the student.
                    </p> 
                </div>
            </div>
            <Footer/>
            <DownFooter/>
        </>
    );
}

export default HolisticLearning;