import React from "react";
import { useState } from "react";

import './Contactus.css';

import Contect from './Contect1';


const Contactus = () => {

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactNumber: '',
    address: '',
    message: ''
  });

  const handleChange = (e) => {
    // console.log(e.target.value,e.target.name);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('http://localhost:8080/demo',{
      method: 'POST',
      body: JSON.stringify(formData),
      headers:{
        'Content-Type': 'application/js',
      }
    })
    const data = await response.text();
    console.log(data); // You can do further processing like sending data to backend here
  };

  return (
    <>
      <div className="contact-us mb-5">
        <div className="heading">
          <h2 className="contact-us-heading text-center mt-4 mb-4">Contact Us</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label className="contact-us-form-text" htmlFor="fullName">Full Name:</label>
              <input type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label className="contact-us-form-text" htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="contact-us-form-text" htmlFor="contactNumber">Contact Number:</label>
              <input type="text" id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label className="contact-us-form-text" htmlFor="address">Address:</label>
              <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
            </div>
          </div>
          <div className="form-group">
            <label className="contact-us-form-text" htmlFor="message">Message:</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>

      
    </>
  );
}
export default Contactus;