import React from "react";
import './Message.css'
import vcp from './imgs/VCPrincipal.jpeg'
import pri from './imgs/Principal.jpeg'
import dir from './imgs/Director.jpeg'

const Message = () => {
    return (
        <>
            <div className="text-center pt-5 text-primary-emphasis">
                <h2>Message</h2>
            </div>
            <div>
                <div className="director-message">
                    <div className="director-photo">
                        <img src={dir} alt="Director" />
                    </div>
                    <div className="director-box">
                        <p>
                            I am delighted to welcome you to the web site of this dynamic institution. We have always placed a great emphasis on the
                            quality of teaching and learning along with the use of highly innovative teaching and delivery methods.
                        </p>
                        <p>
                            Every child must have a school within the comfortable radius of his or her home with the provision of a reliable transport mechanism.
                            Every child has the right to quality education, as upheld by the Constitution of India.
                            The education we impart at Pawa Public is a fine blend of child-centric pedagogy and technology.
                            Every child has the right to affordable education. Pawa Public School is known for its equitable policy on fee structuring.
                            Irrespective of gender or caste, economic status or religion - every child has the right to attend a good school.
                            We follow the strictest security protocol and safety drills to ensure that your child is nurtured in a safe school environment.
                        </p>
                        <h4>- Director</h4>
                        <h4>- Jitendra Kumar Singh</h4>
                    </div>
                </div>

                <div className="director-message">
                    <div className="director-box">
                        {/* <h2>Message from the Principal</h2>
                    <h4>Indra Raman Singh</h4> */}
                        <p>
                            Education has been undergoing a “Creative Revolution” during the past and teaching methods have changed and so has the
                            teacher-pupil relationship. Generally, the methods have moved from a predominantly teacher-directed,
                            teacher controlled approach to a “Teacher-Taught” interactive system in which discovery learning is considered an
                            integral part of the lesson.
                        </p>
                        <p>
                            At Pawa Public, the teachers do instill the social & moral values in to their students besides bookish knowledge.
                        </p>
                        <p>
                            School is not merely a physical structure. It is a place of learning and building one’s destiny. Learning which includes the
                            acquisition of habits, skills and appreciations, is a function in the process of doing and testing. Thinking and doing cannot
                            be separated, together they form the whole human being.
                        </p>
                        <h4>- Principal</h4>
                        <h4>- Indra Raman Singh</h4>
                    </div>

                    <div className="director-photo">
                        <img src={pri} alt="Director" />
                    </div>
                </div>

                <div className="director-message">
                    <div className="director-photo">
                        <img src={vcp} alt="Director" />
                    </div>
                    <div className="director-box">
                        {/* <h2>Message from the Vice Principal</h2>
                    <h4>Pramod Kumar Gupta</h4> */}
                        <p>
                            Its my pleasure to convey my message through this column. The purpose of the education that we are inculcating to the students
                            is not just to produce academicians but to develop overall personality .
                            At Pawa Public Secondary we conscientiously strive for the teaching that should be, full of ideas instead of recklessly stuffed with facts.
                        </p>
                        <p>
                            We provide the material education, divine education to the students so that they can walk through the doors of opportunity and
                            reach their career destinations. Our teachers are well qualified & equipped and share a passion for teaching , and they try
                            to bring out the best among themselves and their students.
                        </p>
                        <p>
                            The management & Staff is committed whole heartedly to make the students learning experience more exciting  and elucidating  one.
                        </p>
                        <h4>- Vice Principal</h4>
                        <h4>- Pramod Kumar Gupta</h4>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Message;