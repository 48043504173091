import React from "react";
import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";


const Building = () => {
    return (
        <>
            <Banner1 />
            <Header1 />
            this is building Page
            <Footer />
            <DownFooter />
        </>
    )
}
export default Building;