import React from "react";

import './PrePrimary.css'

import Banner1 from "./Banner1";
import Header1 from "./Header1";
import Footer from "./Footer";
import DownFooter from "./DownFooter";



const PrePrimary = ()=>{
    return(
        <>
            <Banner1/>
            <Header1/>
            <div className="activities-page">

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Pre-Primary School Fazilnagar Badahara</h1>
                    <p>
                        Pawa Public Secondary School, affiliated to the U.P. Board, was started in 2019 at Badahara with the idea of providing 
                        value-based education that would contribute to the holistic development of children. The Principal, Mr. Indra Raman Singh 
                        believes that it is never too early to start this process and with this in mind, he has taken great care to 
                        formulate a curriculum that provides hands-on learning experiences for children as young as two and a half. 
                        Exploring and Learning by using all five senses is an effective way to challenge and satisfy the curiosity of 
                        children who are natural seekers of knowledge. Well-qualified and trained teachers under the able and meticulous 
                        guidance of Mr. Indra Raman Singh take pride in making Learning a Fun activity for children of the Pre-Nursery and Nursery 
                        Sections. Pawa Public Secondary Schoo is second to none in Fazilnagar in providing an environment that makes coming to School 
                        a joyful learning experience for students of the Pre-Nursery and Nursery Sections.
                        <br/>
                        <br/>
                        The School believes in providing a child-centric education. It rightly boasts of colourful and exciting classrooms for the 
                        Pre-Nursery and Nursery Sections. Enticing toys, scientifically designed games and the constant guidance and encouragement 
                        of the staff at Pawa Public Secondary School help the young learners to listen, observe, imagine, discover, learn and create. 
                        There are varied colourful tools that help children develop their motor skills. Through innovative, interactive learning 
                        processes, children learn the alphabet along with numbers. Children also learn social skills and the confidence that a young 
                        Pawa Public Secondary School exudes testifies to the fact that this School is one of the best Pre-Nursery and Nursery Schools in Fazilnagar.
                        <br/>
                        <br/>
                        
                    </p> 
                </div>

                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Pre-Primary School Admission Procedure</h1>
                    <p>
                        The Admission Procedure of Pawa Public Secondary Pre-Nursery and Nursery School
                        <br/>
                        <br/>
                        Admission is fee in Pre-Nursery and Nursery.
                        <br/>
                        <br/>
                        <h5>Age Criteria</h5>
                        <ul>
                            <li>
                                The age criteria for admission into the Pre-Nursery are minimum of 3 years and a maximum of 4 years as on 30th September
                            </li>
                        </ul>
                        <ul>
                            <li>
                                For Nursery, the child needs to be between the age of 4 years to 5 years as on 30th September   
                            </li>
                        </ul>
                        <ul>
                            <li>
                                For Preparatory, the age criterion is 5 years to 6 years as on 30th September
                            </li>
                        </ul>
                        <br/>
                        <br/>
                    </p> 
                </div>
                <div className="activities-section">
                    <h1 className="text-primary-emphasis">Application Form</h1>
                    <p>
                        Application forms for admission are available online. Forms need to be filled online and print out of the same needs to be submitted to the School in person.
                        Admission will be given after the interview process.
                        <br/>
                        <br/>
                        Submission of an Application Form does not guarantee admission
                        <br/>
                        <br/>
                        <h5>List of Documents Required</h5>
                        <ul>
                            <li>
                                The attested copy of birth certificate should be submitted along with the form
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Attested copies of educational certificates of Parents’ educational qualifications
                            </li>
                        </ul>
                        <ul>
                            <li>
                                In the case of a transfer, the latest school report card should be submitted along with the application form
                            </li>
                        </ul>
                    </p> 
                </div>  
            </div>
            <Footer/>
            <DownFooter/>
        </>
    )
}
export default PrePrimary;